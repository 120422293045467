import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiResponseStatus } from 'models/enums';
import { AdminEmployeeInfo, EmployeeLeaveData, LeaveData } from 'models/models';
import { request } from 'services/apis';
import { toast } from 'react-toastify';

export interface AdminEmployeeState {
  employeeData: AdminEmployeeInfo[];
  getUsersStatus: ApiResponseStatus;
  getUserError: string | null;
  createUserStatus: ApiResponseStatus;
  createUserError: string | null;
  updateUserStatus: ApiResponseStatus;
  updateUserError: string | null;
  allLeaveSummary: EmployeeLeaveData[];
  allLeaveSummaryStatus: ApiResponseStatus;
  allLeaveSummaryError: string | null;
}

export const createEmployee = createAsyncThunk(
  'admin/createAdminEmployeeCreate',
  async (employeeData: AdminEmployeeInfo) => {
    try {
      const response = await request.post<AdminEmployeeInfo>(
        '/employees/create',
        employeeData
      );
      toast.success('Employee created successfully!');
      return response.data;
    } catch (error) {
      console.error(error);
      toast.error('Failed to create employee.');
      throw error;
    }
  }
);

export const updateEmployee = createAsyncThunk(
  'admin/updateAdminEmployeeUpdate',
  async (employeeData: AdminEmployeeInfo) => {
    try {
      const response = await request.put<AdminEmployeeInfo>(
        `/employees/update-employeeInfo?userId=${employeeData.userRoleId}`,
        employeeData
      );
      toast.success('Employee info updated successfully!');
      return response.data;
    } catch (error) {
      console.error(error);
      toast.error('Failed to update employee info.');
      throw error;
    }
  }
);

export const getAdminEmployeeInfo = createAsyncThunk(
  'admin/getAdminEmployeeInfo',
  async () => {
    try {
      const response = await request.get<AdminEmployeeInfo[]>(
        '/employees/all-info'
      );

      const activeEmployees = response.data.sort((a, b) =>
        a.epfNumber?.localeCompare(b.epfNumber)
      );
      return activeEmployees;
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch employee info.');
      throw error;
    }
  }
);

export const getAllLeaveSummary = createAsyncThunk(
  'admin/getAllLeaveSummary',
  async () => {
    try {
      const response = await request.get<EmployeeLeaveData[]>(
        '/Leave/leave-allsummary'
      );
      const activeEmployees = response.data
        .filter(
          (employee) =>
            employee.isActive === true || employee.isActive === 'true'
        )
        .sort((a, b) => a.epfNumber.localeCompare(b.epfNumber));

      console.log('Active Employees:', activeEmployees);
      return activeEmployees;
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch leave summary info.');
      throw error;
    }
  }
);

export const getLeaveDetails = createAsyncThunk(
  'admin/getLeaveDetails',
  async (email: string) => {
    try {
      const response = await request.get<LeaveData>(
        `/Leave/leave-details/${email}`
      );
      return response.data;
    } catch (error) {
      toast.error('Failed to fetch leave details');
      throw error;
    }
  }
);

const initialState: AdminEmployeeState = {
  employeeData: [],
  getUsersStatus: ApiResponseStatus.idle,
  getUserError: null,
  createUserStatus: ApiResponseStatus.idle,
  createUserError: null,
  updateUserStatus: ApiResponseStatus.idle,
  updateUserError: null,
  allLeaveSummary: [],
  allLeaveSummaryStatus: ApiResponseStatus.idle,
  allLeaveSummaryError: null
};

const adminEmployeeSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Create employee info
    builder
      .addCase(createEmployee.pending, (state) => {
        state.createUserStatus = ApiResponseStatus.pending;
        state.createUserError = null;
      })
      .addCase(createEmployee.fulfilled, (state) => {
        state.createUserStatus = ApiResponseStatus.fulfilled;
        state.createUserError = null;
      })
      .addCase(createEmployee.rejected, (state, action) => {
        state.createUserStatus = ApiResponseStatus.rejected;
        state.createUserError = action.error.message ?? 'Unknown error';
      });

    // Get employee info
    builder
      .addCase(getAdminEmployeeInfo.pending, (state) => {
        state.getUsersStatus = ApiResponseStatus.pending;
        state.getUserError = null;
      })
      .addCase(getAdminEmployeeInfo.fulfilled, (state, { payload }) => {
        state.getUsersStatus = ApiResponseStatus.fulfilled;
        state.getUserError = null;
        state.employeeData = payload as AdminEmployeeInfo[];
      })
      .addCase(getAdminEmployeeInfo.rejected, (state, action) => {
        state.getUsersStatus = ApiResponseStatus.rejected;
        state.getUserError = action.error.message ?? 'Unknown error';
      });

    // Update employee info
    builder
      .addCase(updateEmployee.pending, (state) => {
        state.updateUserStatus = ApiResponseStatus.pending;
        state.updateUserError = null;
      })
      .addCase(updateEmployee.fulfilled, (state) => {
        state.updateUserStatus = ApiResponseStatus.fulfilled;
        state.updateUserError = null;
      })
      .addCase(updateEmployee.rejected, (state, action) => {
        state.updateUserStatus = ApiResponseStatus.rejected;
        state.updateUserError = action.error.message ?? 'Unknown error';
      });

    // Get all leave summary
    builder
      .addCase(getAllLeaveSummary.pending, (state) => {
        state.allLeaveSummaryStatus = ApiResponseStatus.pending;
        state.allLeaveSummaryError = null;
      })
      .addCase(getAllLeaveSummary.fulfilled, (state, { payload }) => {
        state.allLeaveSummaryStatus = ApiResponseStatus.fulfilled;
        state.allLeaveSummaryError = null;
        state.allLeaveSummary = payload as EmployeeLeaveData[];
      })
      .addCase(getAllLeaveSummary.rejected, (state, action) => {
        state.allLeaveSummaryStatus = ApiResponseStatus.rejected;
        state.allLeaveSummaryError = action.error.message ?? 'Unknown error';
      });

    // Get all leave details
    // builder
    //   .addCase(getLeaveDetails.pending, (state) => {
    //     state.leaveDetailsStatus = 'loading';
    //     state.leaveDetailsError = null;
    //   })
    //   .addCase(
    //     getLeaveDetails.fulfilled,
    //     (state, action: PayloadAction<LeaveDetails>) => {
    //       state.leaveDetailsStatus = 'succeeded';
    //       state.leaveDetails = action.payload;
    //     }
    //   )
    //   .addCase(getLeaveDetails.rejected, (state, action) => {
    //     state.leaveDetailsStatus = 'failed';
    //     state.leaveDetailsError =
    //       action.error.message || 'Failed to fetch leave details';
    //   });
  }
});

const { actions, reducer } = adminEmployeeSlice;

export default reducer;
