import React from 'react';
import { EmployeeSummaryFilter, Chip, Typography } from 'components';
import { RootState, useAppSelector } from 'store';
import { useNavigate } from 'react-router';
import { AdminEmployeeInfo } from 'models/models';
import './index.scss';

export const EmployeeSummary = () => {
  const navigate = useNavigate();

  const employeeData = useAppSelector((state: RootState) => {
    return state.admin.employeeData.filter((employee) => employee.isActive);
  });

  const inactiveEmployeeData = useAppSelector((state: RootState) => {
    return state.admin.employeeData.filter((employee) => !employee.isActive);
  });

  const handleEmployeeClick = (employee: AdminEmployeeInfo) => {
    navigate(`/admin/view-employee/${employee.userRoleId}`);
  };

  return (
    <div className="employee-summary">
      <EmployeeSummaryFilter />

      <div className="employee-summary-container">
        <table>
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Employee Name</th>
              <th>Designation</th>
              <th>Email</th>
              <th>NIC</th>
              <th>Mobile Number</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {employeeData.length === 0 ? (
              <tr>
                <td colSpan={7}>No employee found</td>
              </tr>
            ) : (
              employeeData.map((employee) => (
                <tr key={employee.userRoleId}>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.epfNumber}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.employeeNickName}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.role}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.upn}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.nic}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.mobileNumber}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    <div className="employee-status-chip">
                      {employee.status && (
                        <Chip text={employee.status} status={employee.status} />
                      )}
                    </div>
                  </td>
                  <td>
                    <button
                      className="admin-edit"
                      type="button"
                      onClick={() =>
                        navigate(`/admin/edit-employee/${employee.userRoleId}`)
                      }
                    >
                      <img src="/icons/admin-edit.svg" alt="" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="employee-inactive-title">
        <Typography
          text="Inactive Employees"
          type="base_regular"
          weight="bold"
        />
      </div>

      <div className="employee-summary-container">
        <table>
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Employee Name</th>
              <th>Designation</th>
              <th>Email</th>
              <th>NIC</th>
              <th>Mobile Number</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {inactiveEmployeeData.length === 0 ? (
              <tr>
                <td colSpan={7}>No employee found</td>
              </tr>
            ) : (
              inactiveEmployeeData.map((employee) => (
                <tr key={employee.userRoleId}>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.epfNumber}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.employeeNickName}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.role}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.upn}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.nic}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    {employee.mobileNumber}
                  </td>
                  <td
                    className="clickable"
                    onClick={() => handleEmployeeClick(employee)}
                  >
                    <div className="employee-status-chip">
                      {employee.status && (
                        <Chip text={employee.status} status={employee.status} />
                      )}
                      {!employee.isActive && (
                        <Chip text="Inactive" status="Terminated" />
                      )}
                    </div>
                  </td>
                  <td>
                    <button
                      className="admin-edit"
                      type="button"
                      onClick={() =>
                        navigate(`/admin/edit-employee/${employee.userRoleId}`)
                      }
                    >
                      <img src="/icons/admin-edit.svg" alt="" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
